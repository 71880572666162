<template>
  <div class="body">
    <UnauthHeader />
    <!-- banner部分 -->
    <div class="topBanner">
      <div class="projectCenter">
        <div class="bannerTitle">关于发布2024上海市医疗大模型应用示范场景名单及开展供需对接工作的通知</div>
        <div class="toSignBtn" @click="jump">
          <div class="content">供需对接入口</div>
          <img src="@/assets/first-page/goJump.png" class="goJump" alt="" />
        </div>
        <!-- <span class="abortHint">申报截止时间延长至2024年7月15日</span> -->

      </div>
    </div>
    <!-- 项目背景 -->
    <div class="backgroundIdea">
      <div class="projectInfo">
        <div class="projectCenter">
          <div class="proLeft">
            <div class="proNameFirst">项目背景</div>
            <img class="titleFirst" src="@/assets/first-page/title.png" alt="">

            <div class="proMesFirst">
              为贯彻落实党中央、国务院关于开展“人工智能+”行动的重要部署，落实《关于推动未来产业创新发展的实施意见》（工信部联科〔2024〕12号）《上海市推动人工智能大模型创新发展若干措施（2023-2025年）》（沪经信智〔2023〕608号）有关要求，重点支持在医疗领域构建人工智能大模型应用示范场景，打造标杆性大模型产品和服务，市经济信息化委、市卫生健康委、市委网信办拟联合开展2024年本市医疗大模型应用示范场景征集工作，由中国信通院上海工创中心负责组织管理工作。
            </div>
            <div class="infoLink-first" @click="downloadFile" style="cursor: pointer;">
              <img src="@/assets/first-page/file.svg" alt="">
              关于组织开展2024本市医疗大模型应用示范场景征集的通知
              <img src="@/assets/first-page/loadimg.png" alt="" style="width:12px;height:12px;margin-left: 10px">
            </div>
            <!-- <div class="infoLink-first1" @click="downloadFileSecond" style="cursor: pointer;">
              <img src="@/assets/first-page/file.svg" alt="">
              上海市医疗大模型应用示范场景征集表
              <img src="@/assets/first-page/loadimg.png" alt="" style="width:12px;height:12px;margin-left: 10px">
            </div> -->
            <div class="infoLink-first1" @click="downloadFileThree" style="cursor: pointer;">
              <img src="@/assets/first-page/file.svg" alt="" >
              关于发布2024上海市医疗大模型应用示范场景名单及开展供需对接工作的通知
              <img src="@/assets/first-page/loadimg.png" alt="" style="width:12px;height:12px;margin-left: 10px">
            </div>

          </div>
          <img class="proRight" src="../../assets/first-page/projectBackground.webp" alt="" />
        </div>
      </div>
      <!-- 思路 -->
      <div class="questionInform imgWrapper">
        <div class="projectCenter">
          <div class="proNameSecond" style="text-align: center;">工作思路</div>
          <img class="title" src="@/assets/first-page/title.png" alt="" style="text-align: center">

          <div class="questionUl">
            <div class="questionLi">
              <!-- 图片1/2/3-->
              <div class="questionMes">
                <div class="questionIco">
                  <img src="../../assets/first-page/sl1.png" alt="" />
                </div>
                <div class="qmName">聚焦五大方向，九大重点场景</div>
                <div class="qmTxt">
                  聚焦医疗服务、卫生管理、创新研发、基础支撑、综合示范平台五大方向，诊疗服务、患者服务、中医诊疗、医院管理、疾控监测、临床科研、药物研发、医疗语料库、综合示范平台九大重点场景。
                </div>
              </div>
            </div>
            <div class="questionLi">
              <!-- 图片1/2 -->
              <div class="questionMes">
                <div class="questionIco">
                  <img src="../../assets/first-page/sl2.png" alt="" />
                </div>
                <div class="qmName">完成全流程工作</div>
                <div class="qmTxt">
                  完成应用场景征集及供需对接全流程工作，包括场景征集、厘清需求、供需对接等工作环节。
                </div>
              </div>

            </div>
            <div class="questionLi">
              <div class="questionIco">
                <img src="../../assets/first-page/sl3.png" alt="" />
              </div>
              <div class="questionMes">
                <div class="qmName">重点关注供需对接</div>
                <div class="qmTxt">
                  重点关注供需对接，围绕主题方向举办多场专题供需对接会，邀请同领域、高层级的专家共同参与，助力产业各方精准匹配供需资源，实现高效合作。
                </div>
              </div>
            </div>
            <div class="questionLi">
              <div class="questionIco">
                <img src="../../assets/first-page/sl4.png" alt="" />
              </div>
              <div class="questionMes">
                <div class="qmName">更加聚焦多元互通</div>
                <div class="qmTxt">
                  更加聚焦多元互通，推动场景间沟通协作、资源协同与数据共享，促进多向导流，以形成智能化、多元化、个性化的医疗大模型标杆示范。
                </div>
              </div>
            </div>
          </div>
        </div>
        <img class="imgLeft" src="../../assets/first-page/roundLeft.png" alt="">
        <img class="imgRight" src="../../assets/first-page/roundRight.webp" alt="">
      </div>
    </div>

      <!--配套支持-->
      <div class="factorsupport" style="height: 798px;">
      <div class="projectCenter" style="height: 798px;">
        <div class="proName" style="text-align: center;">配套支持</div>
        <img class="title" src="@/assets/first-page/title.png" alt="">
        <div class="supportcontent">
          <div class="supportLeft">
            <div class="supportleftTop">
              <div class="supportTitle">挂牌示范中心</div>
              <div class="supportBody">本次场景评选入围项目拟纳入市级专项课题管理。对后期验收结果“优秀”的项目，将给予建设主体单位挂牌“上海市医疗大模型（细分领域）应用场景示范中心”。
              </div>
            </div>
            <div class="supportleftBottom">
              <div class="supportTitle">强化政策支持</div>
              <div class="supportBody">
                对符合条件的医疗大模型应用示范场景，通过市级专项资金等方式予以优先考虑;对纳入应用示范场景解决方案的创新产品，优先推荐纳入《上海市创新产品推荐目录》。
                 </div>
            </div>

          </div>
          <div class="middle">
            <img src="@/assets/first-page/supportBg.webp" alt="" style="width:524px;height:306px" />
          </div>
          <div class="supportRight">
            <div class="supportrightTop">
              <div class="supportTitle">加强组织保障</div>
              <div class="supportBody"> 广泛动员各类主体积极参与，在后续申报中给予相关支持和跟踪服务。鼓励各区对入围应用示范场景的属地技术企业给予相关保障服务。</div>
            </div>
            <div class="supportrightBottom">
              <div class="supportTitle">开展宣传推广</div>
              <div class="supportBody">相关主管部门负责开展宣传推广，通过官方媒体平台，支持入围单位提升品牌知名度并推广其场景应用。
              </div>
            </div>
          </div>

          <div class="supportBottom">
            <div class="supportrightTop">
              <div class="supportTitle">组织供需对接</div>
              <div class="supportBody">相关主管部门位将积极发挥协同引导作用，组织主题沙龙、专场论坛或需求路演等活动，推动需求方与解决方案提供商进行充分沟通对接，精准匹配供需资源。 </div>
            </div>
          </div>






        </div>
      </div>

    </div>
    <!--    征集范围/重点方向-->
    <div class="scale">
      <div class="projectCenter">
        <div class="proName3" style="text-align: center;">征集范围+重点方向</div>
        <div style="text-align: center;">
          <img class="title" src="@/assets/first-page/title.png" alt="">
        </div>
        <div class="scaleContent">
          <div class="scaleLeft">
            <div class="leftTop">
              <div class="leftTop-img">
                <img src="@/assets/first-page/lefttop.png" alt="">
              </div>
              <div class="leftTop-content">
                <div class="leftTopTitle">一 医疗服务</div>
                <div class="leftTopContent">
                  <div class="content1">
                    <div class="list">
                      <div class="list-content">01</div>
                    </div>
                    <div class="cnt">
                      <div class="sub-title">大模型+诊疗服务</div>
                      <div class="main-content">
                        支持打造面向诊疗服务的大模型创新应用场景，形成覆盖全身多部位、多病种、多模态数据的全流程智慧辅助诊疗服务平台，极大提升诊疗精准度、效率和服务质量。</div>
                    </div>
                  </div>
                  <div class="content1">
                    <div class="list">
                      <div class="list-content">02</div>
                    </div>
                    <div class="cnt">
                      <div class="sub-title">大模型+患者服务</div>
                      <div class="main-content">
                        支持打造面向患者的智能化交互应用场景，构建涵盖智能问诊、精准用药指导、慢病管理、患者随访等多元化应用的大模型超级医生平台，全面提升患者体验和满意度。</div>
                    </div>
                  </div>
                  <div class="content1">
                    <div class="list">
                      <div class="list-content">03</div>
                    </div>
                    <div class="cnt">
                      <div class="sub-title">大模型+中医诊疗</div>
                      <div class="main-content">支持打造面向中医的诊疗养一体化应用场景，构建基于名老中医临床诊疗数据、中医典籍、中医文献数据到内外妇儿等全学科知识图谱的中医智能辅助诊疗平台。
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <!--            左边底部区域-->
            <div class="rightBottom">
              <div class="rightBottom-img">
                <img src="@/assets/first-page/rightbottom.png" alt="">
              </div>
              <div class="leftBottomTitle">三 创新研发</div>
              <div class="leftBottomContent">
                <div class="content2">
                  <div class="leftBottom-list">
                    <div class="list-content">06</div>
                  </div>
                  <div class="rightTop-cnt">
                    <div class="bottomless-title">大模型+临床科研</div>
                    <div class="bottommain-content">
                      支持打造面向临床科研的创新应用场景，形成集多中心临床、智能病例入组、智能数据分析等应用于一体的科研服务体系，降低科研成本，显著提升科研成果质量和转化效率。</div>
                  </div>
                  <div class="leftBottom-list">
                    <div class="list-content">07</div>
                  </div>
                  <div class="leftBottom-cnt">
                    <div class="bottomless-title">大模型+药物研发</div>
                    <div class="bottommain-content">
                      支持打造面向药企的一站式智能研发应用场景，建立涵盖AI药物虚拟筛选、生成式AI药物设计、药物靶点发现、创新分子优化等环节的药物研发大模型，全面提高药物研发效率和成功率。</div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="scaleRight">
            <div class="rightTop">
              <div class="rightTop-img">
                <img src="@/assets/first-page/leftbottom2.png" alt="">
              </div>
              <div class="leftBottomTitle">二 卫生管理</div>
              <div class="leftBottomContent">
                <div class="content2">
                  <div class="leftBottom-list">
                    <div class="list-content">04</div>
                  </div>
                  <div class="rightTop-cnt">
                    <div class="bottomless-title">大模型+医院管理</div>
                    <div class="bottommain-content">
                      支持打造面向医院的智能管理决策AI原生应用场景，整合智能医院报表、智慧病房管理、重大事件管控、智能药物管理等功能，优化医院资源配置，全方位提高运营效率和风险防控能力。</div>
                  </div>
                  <div class="leftBottom-list">
                    <div class="list-content">05</div>
                  </div>
                  <div class="leftBottom-cnt">
                    <div class="bottomless-title">大模型+疾控监测</div>
                    <div class="bottommain-content">
                      支持打造面向疾病防控及监测的智慧化应急响应和决策平台应用场景，建立涵盖动态疾病预测、社交媒体监测与干预、卫生资源实时调配等场景的智能疾控监测服务平台。</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="leftBottom">
              <div class="leftBottomContent">
                <div class="leftBottom-img">
                  <img src="@/assets/first-page/righttop2.png" alt="">
                </div>
                <div class="leftBottomTitle">四 基础支撑</div>
                <div class="leftBottomContent">
                  <div class="content2">
                    <div class="leftBottom-list">
                      <div class="list-content">08</div>
                    </div>
                    <div class="leftBottom-cnt">
                      <div class="bottomless-title">大模型+医疗语料库</div>
                      <div class="bottommain-content">
                        支持打造面向大模型的动态流通医疗数据资源，融合多语种医疗语料文本、医学研究文献、中文医疗术语、全病种医学知识库等高质量医疗语料资源，语料库在规模和覆盖范围上达到全球领先水平。</div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            <div class="RightLast">
              <div class="RightBottomContent">
                <div class="RightLast-img">
                  <img src="@/assets/first-page/RightBottomSecond.png" alt="">
                </div>
                <div class="RightLastTitle">五 综合示范平台</div>
                <div class="leftBottomContent">
                  <div class="content2">
                    <div class="leftBottom-list">
                      <div class="list-content">09</div>
                    </div>
                    <div class="leftBottom-cnt">
                      <div class="bottomless-title">大模型综合示范平台</div>
                      <div class="bottommain-content">
                        面向创新药物研发、人形机器人、脑机接口、临床试验、医学科研、医学教育、公共卫生、中医药等大模型赋能的重点细分领域，打造在垂直细分领域覆盖面广、场景全面、技术先进、示范效应强的综合示范平台。
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>


          </div>
        </div>
      </div>

    </div>
    <!-- 征集要求-->
    <div class="recruit">
      <div class="projectCenter">
        <div class="proName" style="text-align: center;">征集要求</div>
        <img class="title" src="@/assets/first-page/title.png" alt="">
        <div class="recruitBody">
          <div class="recruitLeft">
            <img src="@/assets/first-page/recruitBg.webp" alt="" style="width: 500px;height: 400px;">
          </div>
          <div class="recruitRight">
            <div class="recruitLi">
              <div class="recruiticon">
                <img src="@/assets/first-page/recruit1.png" alt="" style="width: 20px;height:20px">
              </div>
              <div class="recruitContent">
                <div class="recruitTitle">征集场景对标</div>
                <div class="recruitmain-body">征集场景应对标前沿，在医疗大模型领域内具有引领带动作用，在本市乃至国内能够形成应用示范效应，具备可复制性、可推广性。</div>
              </div>
            </div>
            <div class="recruitLi">
              <div class="recruiticon">
                <img src="@/assets/first-page/recruitDesc.png" alt="" style="width: 20px;height:20px">
              </div>
              <div class="recruitContent">
                <div class="recruitTitleDirection">征集应用建设方向</div>
                <div class="recruitmain-body">各应用示范场景建设方向需贴合医疗健康领域实际需求，鼓励技术融合、数据融合、业务融合以及跨层级、跨系统、跨部门的协同联动。</div>
              </div>
            </div>
            <div class="recruitLi">
              <div class="recruiticon">
                <img src="@/assets/first-page/recruitGo.png" alt="" style="width: 20px;height:20px">
              </div>
              <div class="recruitContent">
                <div class="recruitTitle">征集态势</div>
                <div class="recruitmain-body">征集场景应具有创新性和实用性，能够体现大模型技术在具体场景中的应用价值。建设内容需包含大模型算法、框架等基础原创性技术。</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 申报条件 -->
    <div class="applyCondition">
      <div class="projectCenter">
        <div class="applyproName" style="text-align: left;">申报条件</div>
        <img class="titleFirst" src="@/assets/first-page/title.png" alt="">
        <div class="applyContent">
          <div class="applyList">
            <div class="applyicon">
              <img src="@/assets/first-page/applyimg.png" alt="">
            </div>
            <div class="applymain-body">
              申报主体为在上海市内注册、具备独立法人资格的医疗机构、企业、高等院校、科研院所、产业园区等机构单位。
            </div>
          </div>
          <div class="applyList">
            <div class="applyicon">
              <img src="@/assets/first-page/applyimg.png" alt="">
            </div>
            <div class="applymain-body">
              申报主体应具备支撑应用示范场景建设和运营的必要基础，在质量、安全、信誉和社会责任等方面无不良记录。
            </div>
          </div>
          <div class="applyList">
            <div class="applyicon">
              <img src="@/assets/first-page/applyimg.png" alt="">
            </div>
            <div class="applymain-body">
              允许以联合体方式参与申报，牵头单位为1家，联合单位原则上不超过5家。
            </div>
          </div>
          <div class="applyList">
            <div class="applyicon">
              <img src="@/assets/first-page/applyimg.png" alt="">
            </div>

            <div class="applymain-body">
              各单位可申报多个场景，若需要申报多个场景，需分多次申报。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--申报方式/步骤 -->
    <div class="applyway">
      <div class="projectCenter">
        <div class="proName" style="text-align: center;">申报方式/步骤</div>
        <img class="title" src="@/assets/first-page/title.png" alt="">
        <div class="applywayBody">
          <img src="../../assets/first-page/applybg.png" alt="" style="width:1200px;height:140px" />
          <div class="applycontainer">
            <div class="applycontent">
              <div class="applytitle">材料申报</div>
              <div class="applydesc">申报主体按照要求编制应用示范场景征集表（见附件），并形成相关建设方案，于2024年7月15日之前登录“上海市医疗大模型应用示范场景申报系统”（网址：https://llm.3incloud.com）进行申报，完成单位注册并通过认证后上传申报材料。</div>
            </div>
            <div class="applycontent">
              <div class="applytitle">场景遴选</div>
              <div class="applydesc">对征集到的场景项目进行遴选，经专家评审，择优入围2024年上海市医疗大模型应用示范场景，对入围项目纳入专项管理，进行动态评估和支持。</div>
            </div>
            <div class="applycontent">
              <div class="applytitle">供需对接</div>
              <div class="applydesc">在场景建设期间，组织主题沙龙、专场论坛或需求路演等活动，推动需求方与解决方案提供商进行充分沟通对接，精准匹配供需资源。</div>
            </div>
            <div class="applycontent">
              <div class="applytitle">组织验收</div>
              <div class="applydesc">组织开展医疗大模型应用示范场景验收工作，结合专家评审与第三方测评对完成建设的场景进行综合评价，择优挂牌上海市医疗大模型应用场景示范中心。</div>
            </div>
          </div>

        </div>
      </div>
    </div>
  
    <!--    联系方式-->
    <div class="contactway">
      <div class="projectCenter">
        <div class="proName" style="text-align: center;">联系方式</div>
        <img class="title" src="@/assets/first-page/title.png" alt="">
        <div class="contactmainContent">
          <div class="contactTitle">各部门咨询联系人
            <div class="line"></div>
          </div>

          <div class="contactContent">

            <div class="contactBody">
              <div class="contactdepart">市经济和信息化委员会</div>
              <div class="contactli">
                <div class="contactnameimg">
                  <img src="@/assets/first-page/contactname02.png" alt="" style="width: 18px; height: 18px;">
                </div>
                <div class="contactphone">俞老师</div>
              </div>

              <div class="contactli">
                <div class="contactphoneimg">
                  <img src="@/assets/first-page/contactphone02.png" alt="" style="width: 18px; height: 18px;">
                </div>
                <div class="contactphone">021-23112787</div>
              </div>
            </div>
            <div class="contactBody">
              <div class="contactdepart">市卫生健康委员会</div>
              <div class="contactli">
                <div class="contactnameimg">
                  <img src="@/assets/first-page/contactname2.png" alt="" style="width: 18px; height: 18px;">
                </div>
                <div class="contactphone">唐老师</div>
              </div>

              <div class="contactli">
                <div class="contactphoneimg">
                  <img src="@/assets/first-page/contactphone.png" alt="" style="width: 18px; height: 18px;">
                </div>
                <div class="contactphone">021-23117727</div>
              </div>
            </div>
            <div class="contactBody">
              <div class="contactdepart">市委网络安全和信息化委员会办公室</div>
              <div class="contactli">
                <div class="contactnameimg">
                  <img src="@/assets/first-page/contactname2.png" alt="" style="width: 18px; height: 18px;">
                </div>
                <div class="contactphone">郑老师</div>
              </div>

              <div class="contactli">
                <div class="contactphoneimg">
                  <img src="@/assets/first-page/contactphone.png" alt="" style="width: 18px; height: 18px;">
                </div>
                <div class="contactphone">021-64271063</div>
              </div>
            </div>
            <div class="contactBody">
              <div class="contactdepart">中国信通院上海工创中心</div>
              <div class="contactli">
                <div class="contactnameimg">
                  <img src="@/assets/first-page/contactname2.png" alt="" style="width: 18px; height: 18px;">
                </div>
                <div class="contactphone">李老师</div>
              </div>

              <div class="contactli">
                <div class="contactphoneimg">
                  <img src="@/assets/first-page/contactphone.png" alt="" style="width: 18px; height: 18px;">
                </div>
                <div class="contactphone">18110062940</div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div>
    </div>
    <div class="footerModule"> <a href="https://beian.miit.gov.cn/"
        style="color:rgba(255,255,255,0.6);text-decoration: none;">沪ICP备17037709号-3号</a></div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
import UnauthHeader from '@/components/header/UnauthHeader.vue';
import { downloadFileSimple, getToken, getUserRole } from '@/utils';
import { download_files } from '@/store/config';
const content = ref('支持打造面向中医的诊疗养一体化应用场景，构建基于名老中医临床诊疗数')
export default defineComponent({
  name: 'ProjectSignUp',
  components: {
    UnauthHeader,
  },
  data() {
    return {
      role: '',
      token: '',
      isEnd: false,
      download_url: download_files.sign_up_file,
      isExpanded: false,
      collapsedHeight: 78, // 默认高度
      expandedHeight: 98,// 展开后的高度
      content: '支持打造面向中医的诊疗养一体化应用场景，构建基于名老中医临床诊疗数',
      maxLength: 70, // 初始显示的最大字符数
      currentImage: 'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng3c412f14791de40b77178cae926f1c47d4dbefd6b8a40e5e2cc5f4040cacc94c',
      originalImage: 'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng3c412f14791de40b77178cae926f1c47d4dbefd6b8a40e5e2cc5f4040cacc94c',
      newImage: 'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng8c6803635565fc81db27200ce685053c0a04adc1dd2910c2afb8b70a7c627c80', // 替换的新图片链接
    };
  },
  methods: {
    openNewPage() {
      window.open('https://wap.miit.gov.cn/jgsj/txs/wjfb/art/2023/art_fdb60446250241418fc20e85b35b5818.html', '_blank')
    },
    extendMore() {
      // const content = '支持打造面向中医的诊疗养一体化应用场景，构建基于名老中医临床诊疗数据、中医典籍、中医文献数据到内外妇儿等全学科知识图谱的中医智能辅助诊疗平台。'
      // console.log(content)
      // document.querySelector('.main-content1').innerHTML = content
      this.isExpanded = !this.isExpanded;
    },
    changeImage() {
      this.currentImage = this.newImage;
    },
    restoreImage() {
      this.currentImage = this.originalImage;
    },
    downloadFile() {
      // 关于组织开展2023年“宽带网络+健康乡村”应用试点项目申报工作的通知.docx notice.docx
      downloadFileSimple('/indexInform.pdf', '关于组织开展2024本市医疗大模型应用示范场景征集的通知.pdf')
      // window.open('https://inos.3incloud.com/public/xcjk/%E5%85%B3%E4%BA%8E%E7%BB%84%E7%BB%87%E5%BC%80%E5%B1%952023%E5%B9%B4%E2%80%9C%E5%AE%BD%E5%B8%A6%E7%BD%91%E7%BB%9C+%E5%81%A5%E5%BA%B7%E4%B9%A1%E6%9D%91%E2%80%9D%E5%BA%94%E7%94%A8%E8%AF%95%E7%82%B9%E9%A1%B9%E7%9B%AE%E7%94%B3%E6%8A%A5%E5%B7%A5%E4%BD%9C%E7%9A%84%E9%80%9A%E7%9F%A5.docx');
    },
    downloadFileSecond() {
      // 关于组织开展2023年“宽带网络+健康乡村”应用试点项目申报工作的通知.docx notice.docx
      downloadFileSimple('/indexCollect.docx', '上海市医疗大模型应用示范场景征集表.docx')
      // window.open('https://inos.3incloud.com/public/xcjk/%E5%85%B3%E4%BA%8E%E7%BB%84%E7%BB%87%E5%BC%80%E5%B1%952023%E5%B9%B4%E2%80%9C%E5%AE%BD%E5%B8%A6%E7%BD%91%E7%BB%9C+%E5%81%A5%E5%BA%B7%E4%B9%A1%E6%9D%91%E2%80%9D%E5%BA%94%E7%94%A8%E8%AF%95%E7%82%B9%E9%A1%B9%E7%9B%AE%E7%94%B3%E6%8A%A5%E5%B7%A5%E4%BD%9C%E7%9A%84%E9%80%9A%E7%9F%A5.docx');
    },
    downloadFileThree() {
      downloadFileSimple('/announcement.pdf', '关于发布2024上海市医疗大模型应用示范场景名单及开展供需对接工作的通知.pdf')
      // window.open('https://inos.3incloud.com/public/xcjk/%E5%85%B3%E4%BA%8E%E7%BB%84%E7%BB%87%E5%BC%80%E5%B1%952023%E5%B9%B4%E2%80%9C%E5%AE%BD%E5%B8%A6%E7%BD%91%E7%BB%9C+%E5%81%A5%E5%BA%B7%E4%B9%A1%E6%9D%91%E2%80%9D%E5%BA%94%E7%94%A8%E8%AF%95%E7%82%B9%E9%A1%B9%E7%9B%AE%E7%94%B3%E6%8A%A5%E5%B7%A5%E4%BD%9C%E7%9A%84%E9%80%9A%E7%9F%A5.docx');
    },
    jump() {
      this.$router.push('/demand')
      // if (this.token) {
      //   if (this.role === 'ROLE_USER') {
      //     this.$router.push('/proj-apls/center/1');
      //   } else if (
      //     this.role === 'ROLE_PLATFORM_ADMIN' ||
      //     this.role === 'ROLE_PLATFORM_USER'
      //   ) {
      //     //平台管理员或超级管理员
      //     this.$router.push('/admin/user/registered/list/1');
      //   } else if (
      //     this.role === 'ROLE_INSTITUTION_ADMIN_LOWER' ||
      //     this.role === 'ROLE_INSTITUTION_ADMIN' ||
      //     this.role === 'ROLE_INSTITUTION_USER'
      //   ) {
      //     //初审单位用户或管理员
      //     this.$router.push('/proj-mgmt/list/1');
      //   } else if (this.role === 'ROLE_EXPERT') {
      //     this.$router.push('/proj-review');
      //   }
      // } else {
      //   this.$router.push(`/auth/login`);
      // }
    },
  },
  created() {
    this.token = getToken();
    this.role = getUserRole();
    if (1639583999000 > new Date().getTime()) {
      this.isEnd = false;
    } else {
      this.isEnd = true;
    }
  },
});
</script>

<style scoped>
.supportBottom{
  width:320px;
  height: 138px;
  position: absolute;
  bottom: 80px;
  left: 50%;
  margin-left: -160px;
}
.abortHint {
  position: absolute;
  left: 186px;
  bottom: 10px;
  width: 260px;
  height: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 22px;
  text-align: left;
  font-style: normal;
}

.proName3 {
  width: 100%;
  height: auto;
  line-height: 45px;
  font-size: 32px;
  color: #000000;
  font-weight: 600;
  margin-top: 60px;
  padding: 20px 0 18px 0;
}

.content1 {
  display: flex
}

.RightBottomContent {
  padding-top: 15px;
}

.RightLast-img {
  display: flex;
  width: 64px;
  height: 64px;
  background: #0077FF;
  border-radius: 50%;
  //margin:30px 476px 490px 40px;
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  margin-top: 30px;
  margin-left: 40px;
  margin-bottom: 20px;

}

.RightLastTitle {
  /* width: 200px; */
  height: 33px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 24px;
  color: #333333;
  line-height: 33px;
  text-align: left;
  font-style: normal;
  padding-left: 40px;
  margin-bottom: 30px;
}

.RightLast {
  width: 580px;
  height: 326px;
  background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
  border-radius: 24px;
  margin-top: 40px;
}

.backgroundIdea {
  width: 100%;

}

.applyList {
  display: flex;
  margin-bottom: 24px;

  img {
    width: 18px;
    height: 14px;
    margin-top: 4px;
  }
}

.applyicon {
  /* margin-top: 4px; */
}

.applymain-body {
  margin-left: 12px;

}

.applymain-body {
  width: 1200px;
  height: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 22px;
  text-align: left;
  font-style: normal;
}

.applyproName {
  width: 128px;
  height: 44px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 32px;
  color: #FFFFFF;
  line-height: 44px;
  text-align: left;
  font-style: normal;
  margin-top: 60px;
  margin-bottom: 24px;
}

.applyCondition .titleFirst {
  margin-bottom: 60px;
}

.applyicon img {
  margin-bottom: 4px !important;
}

.line {

  padding-top: 8px;
  height: 8px;
  background: rgba(0, 119, 255, 0.12);
  position: absolute;
  /* top: 100%;  */
  bottom: 0;
  /* 将线移动到文字底部 */
  width: 100%
}

.recruitTitleDirection {
  width: 144px;
  height: 26px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 18px;
  color: #333333;
  line-height: 26px;
  text-align: left;
  font-style: normal;
  margin-bottom: 8px;
}

.title {
  margin: 0 auto;
  display: block;
}

.body {
  overflow-x: hidden;
  /* 禁止水平滚动 */
}

.bannerTitle {
  width: 1000px;
  height: 112px;
  font-family: MicrosoftYaHei, MicrosoftYaHei;
  font-weight: bold;
  font-size: 40px;
  color: #FFFFFF;
  line-height: 56px;
  letter-spacing: 4px;
  text-align: left;
  font-style: normal;
  margin-bottom: 40px;
}

.projectLeft {
  position: relative;
  margin-top: 340px;
  width: 194px;
  height: 157px;
  /* 设置容器的高度为图片高度的一半 */
  overflow: hidden;
  /* 隐藏超出容器的部分 */
}

.topHalf {
  position: absolute;
  /* 绝对定位 */
  top: 0;
  /* 图片顶部与容器顶部对齐 */
  left: 0;
  /* 图片左边与容器左边对齐 */
  width: 100%;
  /* 图片宽度和容器宽度相同 */
}

.bottomHalf {
  position: absolute;
  /* 绝对定位 */
  bottom: 0;
  /* 图片底部与容器底部对齐 */
  left: 0;
  /* 图片左边与容器左边对齐 */
  width: 100%;
  /* 图片宽度和容器宽度相同 */
}

.questionLeft {
  width: 194px;
  /* 设置容器的宽度 */
  height: 157px;
  /* 设置容器的高度 */
  overflow: hidden;
  /* 超出部分隐藏 */
  position: relative;
  /* 相对定位，为了使绝对定位的元素参照它定位 */
}

.questionLeft {
  width: 194px;
  /* 设置容器的宽度 */
  height: 157px;
  /* 设置容器的高度 */
  overflow: hidden;
  /* 超出部分隐藏 */
  position: relative;
  /* 相对定位，为了使绝对定位的元素参照它定位 */
}

.toSignBtn {
  display: flex;
  width: 160px;
  height: 50px;
  background: linear-gradient(270deg, #D9EFFF 0%, #FFFFFF 100%);
  border-radius: 25px;
  cursor: pointer;

}

.toSignBtn img {
  margin-left: 10px;
}

.proLeft {
  width: 100%;
  height: 531px;
  float: left;
}

.proName {
  width: 100%;
  height: auto;
  line-height: 45px;
  font-size: 32px;
  color: #000000;
  font-weight: 600;
  padding: 60px 0 18px 0;

}

.proNameFirst {
  width: 100%;
  height: auto;
  line-height: 45px;
  font-size: 32px;
  color: #000000;
  font-weight: 600;
  //padding: 40px 0 18px 0;
  margin-top: 60px;

}

.proNameSecond {
  width: 100%;
  height: auto;
  line-height: 45px;
  font-size: 32px;
  color: #000000;
  font-weight: 600;
  //padding: 40px 0 18px 0;
  margin-top: 60px;
  margin-bottom: 18px;

}

.proNameImportant {
  width: 100%;
  height: auto;
  line-height: 45px;
  font-size: 32px;
  color: #000000;
  font-weight: 600;
  margin-top: 60px;
  padding: 20px 0 18px 0;

}

.proMesFirst {
  width: 660px;
  height: 140px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  line-height: 28px;
  text-align: left;
  font-style: normal;
  padding-top: 30px;
  box-sizing: content-box;
}

.proMes2 {
  width: 660px;
  height: 84px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  line-height: 28px;
  text-align: left;
  font-style: normal;
  box-sizing: content-box;
}

.proRight {
  position: absolute;
  right: 0;
  top: 0;
  width: 534px;
  height: 472px;
  display: block;
}

.projectCenter {
  width: 1200px;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
}

.body {
  min-width: 1300px;
  font-size: 14px;
  color: #333333;
  line-height: 24px;
  user-select: none;
}

.questionInform img:first-of-type {
  margin: 0 auto 60px;
}

.questionInform img.leftImg {
  width: 480px;
  margin-left: 0;
  margin-right: 0px;
  margin-bottom: 0px;
}

.questionInform {
  display: flex;
  width: 100%;
  height: 567px;
  overflow: hidden;
  padding-bottom: 20px;
  background: #FAFBFF;
}

.infoListCon {
  display: flex;
  justify-content: space-between;
}

.questionInform .infoList {
  width: 676px;
}

.telIco::before {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  background-image: url("../../assets/first-page/telIco.png");
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  top: 2px;
}

.main-contentMes {
  width: 464px;
  height: 30px;
  overflow: hidden;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 26px;
  font-style: normal;
  /* margin-bottom: 20px; */
  margin-left: 10px;
}

.scale {
  margin: auto;
  height: 1456px;
  background-image: url("../../assets/first-page/scalebg002.webp");
  background-repeat: no-repeat;
  background-size: cover;
}

.tag {
  margin-left: 200px;
  margin-top: 30px;
  color: #999;
  /* 展开提示文字颜色 */
  cursor: pointer;
  /* 鼠标指针显示为手型 */
  margin-bottom: 36px;
}

.leftTop.expand .tag .thumbnail_25 {
  background-image: url('/public/images/first-page/arrow_up.webp');
}

.leftTop.expand .tag:hover .thumbnail_25 {
  background-image: url('/public/images/first-page/arrow_up_active.webp');
}

.tag:hover {
  color: rgba(0, 119, 255, 1);
  cursor: pointer;
  /* 这会将鼠标指针变为手型，表明该元素可点击 */
}

.tag:hover .thumbnail_25 {
  background-image: url('/public/images/first-page/arrow_down_active.webp');
}

.tag span {
  float: left;
}

.thumbnail_25 {

  float: left;
  display: block;
  margin: 7px;
  width: 12px;
  height: 12px;
  background-image: url('/public/images/first-page/arrow_down.webp');
  background-size: 100% 100%;
}

/* .thumbnail_25:hover {
  src: "../../assets/first-page/scalebg.png";

} */
.main-content {
  overflow: hidden;
  transition: max-height 0.3s ease;
  /* 添加过渡效果 */
}

.contactnameimg {
  margin-right: 8px;
}

.contactli {
  display: flex;
  margin-bottom: 10px;
}

.contactphoneimg {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.contactdepart {
  width: 176px;
  height: 43px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-bottom: 18px;
}

.contactname {
  width: 42px;
  height: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-bottom: 10px;
}

.contactphone {
  width: 334px;
  height: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-left: 2px;
}

.contactTitle {
  position: relative;
  width: 192px;
  height: 33px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 24px;
  color: #333333;
  line-height: 33px;
  text-align: left;
  font-style: normal;
  margin-top: 40px;
  margin-left: 60px;
  margin-bottom: 30px;
}

.contactContent {
  display: flex;
  margin-left: 60px;
}

.contactBody {
  width: 246px;
  height: 92px;
  margin-right: 39px;
}

.contactmainContent {

  margin-top: 60px;
  width: 1200px;
  height: 257px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: inset 0px 6px 20px 0px rgba(255, 255, 255, 0.5);
  border-radius: 12px;
  border: 1px solid #FFFFFF;
  backdrop-filter: blur(6px);
}

.contactway {
  width: 100%;
  height: 546px;
  background: url("../../assets/first-page/contactimgBg.webp") 0 0 /cover;
}

.middle {
  width: 524px;
  height: 306px;
  margin-top: 25px;
}

.supportTitle {
  margin-bottom: 8px;
  height: 26px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 18px;
  color: #333333;
  line-height: 26px;
  text-align: left;
  font-style: normal;
}

.supportBody {
  width: 320px;
  height: 104px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 26px;
  text-align: left;
  font-style: normal;
  margin-bottom: 80px;
}

.factorsupport {
  width: 100%;
  height: 625px;
  /* background-color: #FAFBFF; */
}

.supportcontent {
  margin-top: 60px;
  //background-color: #0fb803;
  display: flex;
}

.applydesc {
  width: 240px;
  height: 190px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 24px;
  text-align: left;
  font-style: normal;
  margin-bottom: 80px;
}

.applytitle {
  width: 96px;
  height: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 16px;
  color: #333333;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-bottom: 20px;

}

.applycontent {
  margin-right: 79px;

}

.applycontainer {
  display: flex;
}

.recruitTitle {
  width: 108px;
  height: 26px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 18px;
  color: #333333;
  line-height: 26px;
  text-align: left;
  font-style: normal;
  margin-bottom: 8px;
}

.recruitmain-body {
  width: 530px;
  height: 52px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 26px;
  text-align: left;
  font-style: normal;
  margin-bottom: 50px;
}

.recruiticon {
  margin-top: 2px;
  width: 20px;
  height: 20px;
  margin-right: 30px;
}

.recruitLi {
  display: flex;
}

.recruitRight {
  width: 862px;
  height: 358px;
  margin-left: 120px;
  margin-top: 60px;
}

.recruitBody {
  display: flex;
}

.recruitLeft {
  margin-top: 60px;
  width: 500px;
  height: 400px;
  margin-bottom: 38px;
}

.recruit {
  margin: auto;
  width: 100%;
  height: 627px;
  //background-color: #00c1fe;
}

.applyConditionLi {
  margin-left: -600px;
  display: flex;
  width: 1200px;
  height: 22px;
  margin-bottom: 24px;
}

.sub-title {
  margin-left: 76px;
}

.icon {
  margin-right: 12px;
  width: 18px;
  height: 14px;
}

.applybody {
  width: 1200px;
  height: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 22px;
  text-align: left;
  font-style: normal;
}

.applyheader {
  width: 128px;
  height: 68px;
  margin-left: -600px;
  margin-top: 60px;
  margin-bottom: 60px;
}

.applyproName {
  width: 128px;
  height: 44px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 32px;
  color: #FFFFFF;
  line-height: 44px;
  margin-bottom: 18px;
}

.applyCondition {
  width: 100%;
  height: 428px;
  background: url("../../assets/first-page/applybg02.webp") 0 0 / cover;
}

.rightTop-img {
  display: flex;
  width: 64px;
  height: 64px;
  background: #0077FF;
  border-radius: 50%;
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */

  margin-left: 40px;
  margin-bottom: 20px;
}

.rightTop-img img {
  display: block;
  width: 32px;
  height: 32px;
  margin: 16px;
}

.rightBottom-img {
  display: flex;
  width: 64px;
  height: 64px;
  background: #0077FF;
  border-radius: 50%;
  //margin:30px 476px 490px 40px;
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  margin-top: 30px;
  margin-left: 40px;
  margin-bottom: 20px;
}

.rightTop-cnt {
  margin-left: 76px;
  /* margin-top: -27px; */
  margin-bottom: 20px;
}

.leftBottom-cnt {
  /* margin-left: 76px;
  margin-top: -30px; */
  float: left;
}

.contentMes {
  display: flex;
}

.bottomless-title {
  width: 460px;
  height: 26px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  line-height: 26px;
  text-align: left;
  font-style: normal;
  margin-bottom: 5px;
}

.bottommain-content {
  width: 460px;
  height: 78px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 26px;
  text-align: left;
  font-style: normal;

}

.leftBottomTitle {
  width: 200px;
  height: 33px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 24px;
  color: #333333;
  line-height: 33px;
  text-align: left;
  font-style: normal;
  margin-left: 40px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.scaleContent {
  width: 100%;
  display: flex;
  padding-top: 60px;
  height: 1500px;
}

.scaleLeft {
  display: block;
  width: 580px;
  height: 950px;
  margin-right: 40px;
}

.leftTop-img {
  display: flex;
  width: 64px;
  height: 64px;
  background: #0077FF;
  border-radius: 50%;
  //margin:30px 476px 490px 40px;
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
}

.leftTopTitle {
  /* width: 96px; */
  height: 33px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 24px;
  color: #333333;
  line-height: 33px;
  text-align: left;
  font-style: normal;
  margin-top: 20px;
  margin-bottom: 30px;
}

.list {
  width: 30px;
  height: 25px;
}

.leftBottom-list {
  width: 30px;
  height: 25px;
  margin-left: 40px;
  margin-right: 12px;
  float: left;

  .list-content {
    width: 28px;
    height: 24px;


  }
}

.list-content {
  width: 24px;
  height: 28px;
  font-family: DINAlternate, DINAlternate;
  font-weight: bold;
  font-size: 24px;
  color: #BCC5D3;
  line-height: 28px;
  text-align: left;
  font-style: normal;

}

.sub-title {
  margin-left: 12px;
  width: 464px;
  height: 26px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  line-height: 26px;
  font-style: normal;
  margin-bottom: 5px;
}

.main-content {
  width: 464px;
  height: 78px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 26px;
  font-style: normal;
  margin-bottom: 20px;
  margin-left: 10px;
}

.cnt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.scaleRight {
  display: block;
  width: 580px;
  height: 950px;

}

.leftBottom-img {
  display: flex;
  width: 64px;
  height: 64px;
  background: #0077FF;
  border-radius: 50%;
  //margin:30px 476px 490px 40px;
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  margin-top: 30px;
  margin-left: 40px;
  margin-bottom: 20px;

}

.content {
  /* width: 64px; */
  height: 22px;
  font-weight: 600;
  font-size: 16px;
  color: #0077FF;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  padding-top: 14px;
  padding-bottom: 14px;
  margin-left: 19px;

}

.goJump {
  width: 14px;
  height: 10px;
  margin-top: 20px;

}

.telIco {
  padding: 0 0 0 26px;
  height: auto;
  overflow: hidden;
  position: relative;
}

.nameIco::before {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  background-image: url("../../assets/first-page/nameIco.png");
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  top: 2px;
}

.nameIco {
  padding: 0 0 0 26px;
  height: auto;
  overflow: hidden;
  position: relative;
}

.organWidth {
  width: 100%;
  min-height: 100px;
  height: auto;
  overflow: hidden;
  background-color: #FAFAFA;
}

.applyRangeLi::before {
  content: "";
  display: block;
  width: 18px;
  height: 14px;
  background-image: url("../../assets/first-page/applyIco.png");
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  top: 5px;
}

.applyRangeLi {
  height: auto;
  overflow: hidden;
  position: relative;
  font-size: 14px;
  color: #ffffff;
}

.applyRangeUl {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding-top: 60px;
}

.qmTxt {
  width: 252px;
  min-height: 130px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  line-height: 26px;
  text-align: center;
  font-style: normal;
}

.qmName {
  width: 250px;
  height: 25px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 18px;
  color: #333333;
  line-height: 25px;
  text-align: center;
  font-style: normal;
  margin-bottom: 16px;
}

.questionIco {
  margin: 0 75px 40px 75px;
  width: 100px;
  height: 100px;
  border-radius: 32px;
}

.questionIco img {
  margin: 0 auto 40px auto;
  width: 100px;
  height: 100px;
  //margin: 16px !important;
}

.leftTop {
  width: 580px;
  height: 584px;
  background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
  border-radius: 24px;
  padding-left: 40px;
  padding-top: 30px;
  margin-bottom: 40px;
}

.leftTop.expand {
  position: relative;
  height: fit-content !important;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.06);
  margin-bottom: 0;
}

.leftTop.expand .main-contentMes {
  height: 68px;
  overflow: visible;
}

.leftTopContent {
  display: block;
}

.leftBottom {
  padding-top: 10px;
  width: 580px;
  height: 326px;
  background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
  border-radius: 24px;
}

.rightTop {
  padding-top: 30px;
  width: 580px;
  height: 455px;
  background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
  border-radius: 24px;
  margin-bottom: 40px;
}

.rightBottom {
  padding-top: 10px;
  width: 580px;
  height: 455px;
  background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
  //border-radius: 24px;
  border-radius: 24px;
}

.questionMes {


  height: auto;
  overflow: hidden;
}

.questionLi {
  flex-direction: column;
  display: block;
  width: 300px;
  height: auto;
  overflow: hidden;
  float: left;
  position: relative;
  margin-bottom: 60px;
  z-index: 3;
}

.questionUl {
  width: 100%;
  height: auto;
  overflow: hidden;
}


.infoLink-first {
  color: #2164B1;
  line-height: 28px;
  margin-top: 38px;
  position: relative;
  z-index: 3;
}

.infoLink-first1 {
  color: #2164B1;
  line-height: 28px;
  margin-top: 4px;
  position: relative;

  z-index: 3;
}

.infoLink-second {
  color: #2164B1;
  line-height: 28px;

}

.title {
  //display: block;
  width: 40px;
  height: 6px;
  display: flex;
  text-align: center;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  color: #000000;
  font-weight: normal;
}

.topBanner {
  height: 382px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;
  background: url('@/assets/first-page/go302.webp') 0 0 / cover;
  padding-top: 90px;

}

h1 {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 41px;
  color: #fff;
}

.go {
  display: none;
}

.projectInfo {
  display: flex;
  height: 531px;
  margin: 0 auto;

}

.projectInfo h2 {
  margin-top: 0;
}

.infoContent+.infoContent {
  margin-top: 12px;
}

h2 {
  color: #000000;
  line-height: 45px;
  margin: 0 0 18px;
  font-size: 32px;
  text-align: center;
}

h2+img {
  width: 40px;
  display: block;
  margin: 0 auto 40px;
}


.infoLink img {
  width: 14px;
  margin-right: 8px;
  vertical-align: middle;
}

.viewInfo:hover {
  border: 1px solid #288CFF;
  color: #288CFF;
}

.viewInfo {
  width: 128px;
  line-height: 40px;
  border-radius: 6px;
  border: 1px solid #0077FF;
  color: #0077FF;
  text-align: center;
  cursor: pointer;
}



.infoList h3 {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  line-height: 24px;
  margin: auto;
}

.infoList h3:after {
  content: '';
  width: 0;
  overflow: hidden;
}

.infoList div div {
  margin: 10px 0 30px;
  display: inline-block;
}

.infoList div:last-of-type div {
  margin-bottom: 0px;
}

.questionInform h2,
.applyCondition h2 {
  text-align: center;
}

.applyCondition img {
  margin: 0 0 40px 0;
}

.targetContent {
  width: 600px;
  float: right;
}

.applyRange {
  width: 100%;
  height: 408px;
  background: url("@/assets/first-page/applyRange.webp") center no-repeat;
  background-size: cover;
}

.rangeContent {
  margin-top: 60px;
  /* display: flex; */
  /* justify-content: space-between; */
  color: #666666;
  margin-right: -39px;
}

.rangeContent>div {
  width: 374px;
  min-height: 487px;
  background: #fff;
  border-radius: 12px 12px 0px 0px;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.06);
  float: left;
  margin-right: 39px;
  overflow: hidden;
  transition: all 0.3s linear;
}

.rangeContent>div:hover {
  box-shadow: 0px 0px 40px 0px rgba(0, 119, 255, 0.15);
  border: 1px solid #0077FF;
}

.rangeContent>div:hover .rangeTitle+div {
  -webkit-line-clamp: 30;
}

.rangeTitle+div {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}

.rangeContent img {
  width: 374px;
  height: 280px;
}

.rangeContent img+div {
  padding: 24px;
}

.rangeTitle {
  color: #333333;
  line-height: 25px;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 14px;
}

h3 {
  margin: 24px auto 14px;
  text-align: left;
  color: #333333;
  line-height: 25px;
  font-weight: bold;
  font-size: 22px;
}

.applyCondition {
  //padding: 60px calc(50% - 600px) 0 calc(50% + 80px);
  /* background: url('@/assets/first-page/applyConditionL.png') 0 0 / 50% 100% no-repeat,
    url('@/assets/first-page/applyConditionR.png') 50% 0 / 100% 100% no-repeat; */
  height: 461px;
}

.applyCondition h2 {
  color: #fff;
  text-align: left;
}

.conditionText {
  margin-top: 58px;
  color: #FFFFFF;
  line-height: 26px;
  font-size: 16px;
  position: relative;
  width: 530px;
}

.conditionText::before {
  content: attr(data_index);
  width: 68px;
  line-height: 68px;
  background: #FFFFFF;
  color: #0077FF;
  font-size: 32px;
  border-radius: 50%;
  top: 50%;
  margin-top: -34px;
  position: absolute;
  text-align: center;
  left: -115px;
}

.conditionText:first-of-type {
  margin-bottom: 65px;
}

.conditionText:last-of-type {
  margin-top: 78px;
}

.relatedInfo {
  width: 1200px;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
  color: #666666;
  line-height: 22px;
  padding-bottom: 60px;
}

h3 {
  margin: 60px auto 30px;
  text-align: left;
  position: relative;
}

h3::after {
  content: ' ';
  position: absolute;
  width: 64px;
  height: 12px;
  background: url('@/assets/first-page/h3.png') 0 0 / cover;
  top: 50%;
  margin-top: -6px;
  margin-left: 14px;
}

.workFlowItems {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.workFlowItem img {
  width: 40px;
}

.workFlowItem span {
  font-size: 100px;
  color: #F3F3F3;
  line-height: 140px;
}

.workFlowItems>img {
  width: 63px;
  height: 10px;
  margin: 84px 55px auto 24px;
}

h4 {
  margin-bottom: 19px;
  color: #333333;
  line-height: 22px;
  font-size: 16px;
  font-weight: bold;
}

.applyDate>div {
  display: flex;
  align-items: flex-start;
}

.applyDate img {
  width: 32px;
  margin-right: 19px;
}

.applyDate h4 {
  margin-bottom: 8px;
}

.organStruct {
  margin-bottom: 60px;
}

.organStruct>div {
  display: flex;
  justify-content: space-between;
  color: #666666;
  line-height: 22px;
}

.organStruct h4 {
  margin-bottom: 18px;
}

.organStruct h4+div {
  margin-bottom: 6px;
}

.support {
  color: #333333;
  font-size: 16px;
  line-height: 22px;
}

.support h4 {
  margin-bottom: 30px;
}

.support>div {
  margin-bottom: 16px;
}

.applyConsult>div {
  display: flex;
}

.business,
.technique {
  width: 300px;
  height: 96px;
}

.widthMax {
  width: 380px;
}

.businessTel {
  display: flex;
  justify-content: space-between;
}

.applyConsult h4 {
  margin-bottom: 22px;
  min-height: 44px;
}

.business {
  margin-right: 96px;
}

.businessTel {
  color: #666666;
  line-height: 22px;
}

.applyConsult img {
  width: 18px;
  vertical-align: -3px;
  margin-right: 8px;
}

.mb14 {
  margin-bottom: 14px;
}

.proMes2 {
  padding-top: 16px;
  /* padding-bottom:30px; */
}

.footerModule {
  width: 100%;
  height: 80px;
  background: #33334F;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  line-height: 80px;
}

.imgWrapper {
  position: relative;
  overflow: visible !important;
}

.imgLeft {
  position: absolute;
  left: 0;
  top: -182px;
  width: 194px;
  height: 314px;
  z-index: 1
}

.imgRight {
  position: absolute;
  right: 0;
  top: -177px;
  width: 462px;
  height: 744px;
  z-index: 1
}
</style>
